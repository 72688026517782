/**
 * Start Bootstrap - Landing Page (http://startbootstrap.com/)
 * Copyright 2013-2016 Start Bootstrap
 * Licensed under MIT (https://github.com/BlackrockDigital/startbootstrap/blob/gh-pages/LICENSE)
 */
/* line 7, ../assets/sass/landing-page.scss */
body,
html {
  width: 100%;
  height: 100%;
}

/* line 13, ../assets/sass/landing-page.scss */
body,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Lato","Helvetica Neue",Helvetica,Arial,sans-serif;
  font-weight: 700;
}

/* line 24, ../assets/sass/landing-page.scss */
.topnav {
  font-size: 14px;
}

/* line 28, ../assets/sass/landing-page.scss */
.lead {
  font-size: 18px;
  font-weight: 400;
}

/* line 33, ../assets/sass/landing-page.scss */
.intro-header {
  padding-top: 0;
  /* If you're making other pages, make sure there is 50px of padding to make sure the navbar doesn't overlap content! */
  padding-bottom: 0;
  text-align: center;
  color: #f8f8f8;
  background: url(../img/intro-bg.jpg) no-repeat center center;
  background-size: cover;
}

/* line 42, ../assets/sass/landing-page.scss */
.intro-message {
  position: relative;
  padding-top: 10%;
  padding-bottom: 5%;
}

/* line 48, ../assets/sass/landing-page.scss */
.intro-message > h1 {
  margin: 0;
  text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.6);
  font-size: 5em;
}

/* line 54, ../assets/sass/landing-page.scss */
.intro-divider {
  width: 400px;
  border-top: 1px solid #f8f8f8;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

/* line 60, ../assets/sass/landing-page.scss */
.intro-message > h3 {
  text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.6);
}

/* Sections */
/* line 65, ../assets/sass/landing-page.scss */
.sectionSolutions:not(.sectionIntroSolutions) {
  display: none;
}

/* line 69, ../assets/sass/landing-page.scss */
.sectionIntroSolutions p {
  padding: 15px;
  text-align: center;
}

@media (max-width: 767px) {
  /* line 75, ../assets/sass/landing-page.scss */
  .intro-message {
    padding-bottom: 15%;
  }

  /* line 79, ../assets/sass/landing-page.scss */
  .intro-message > h1 {
    font-size: 3em;
  }

  /* line 83, ../assets/sass/landing-page.scss */
  ul.intro-social-buttons > li {
    display: block;
    margin-bottom: 20px;
    padding: 0;
  }

  /* line 89, ../assets/sass/landing-page.scss */
  ul.intro-social-buttons > li:last-child {
    margin-bottom: 0;
  }

  /* line 93, ../assets/sass/landing-page.scss */
  .intro-divider {
    width: 100%;
  }
}
/* line 98, ../assets/sass/landing-page.scss */
.network-name {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 2px;
}

/* line 105, ../assets/sass/landing-page.scss */
.content-section-a {
  padding: 50px 0;
  background-color: #f8f8f8;
}

/* line 110, ../assets/sass/landing-page.scss */
.content-section-b {
  padding: 50px 0;
  border-top: 1px solid #e7e7e7;
  border-bottom: 1px solid #e7e7e7;
}

/* line 116, ../assets/sass/landing-page.scss */
.section-heading {
  margin-bottom: 30px;
}

/* line 120, ../assets/sass/landing-page.scss */
.section-heading-spacer {
  float: left;
  width: 200px;
  border-top: 3px solid #e7e7e7;
}

/* line 126, ../assets/sass/landing-page.scss */
.banner {
  padding: 100px 0;
  color: #f8f8f8;
  background: url(../img/banner-bg.jpg) no-repeat center center;
  background-size: cover;
}

/* line 133, ../assets/sass/landing-page.scss */
.banner h2 {
  margin: 0;
  text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.6);
  font-size: 3em;
}

/* line 139, ../assets/sass/landing-page.scss */
.banner ul {
  margin-bottom: 0;
}

/* line 143, ../assets/sass/landing-page.scss */
.banner-social-buttons {
  float: right;
  margin-top: 0;
}

@media (max-width: 1199px) {
  /* line 149, ../assets/sass/landing-page.scss */
  ul.banner-social-buttons {
    float: left;
    margin-top: 15px;
  }
}
@media (max-width: 767px) {
  /* line 156, ../assets/sass/landing-page.scss */
  .banner h2 {
    margin: 0;
    text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.6);
    font-size: 3em;
  }

  /* line 162, ../assets/sass/landing-page.scss */
  ul.banner-social-buttons > li {
    display: block;
    margin-bottom: 20px;
    padding: 0;
  }

  /* line 168, ../assets/sass/landing-page.scss */
  ul.banner-social-buttons > li:last-child {
    margin-bottom: 0;
  }
}
/* line 173, ../assets/sass/landing-page.scss */
footer {
  padding: 20px 0;
  background-color: #f8f8f8;
  text-align: center;
}

/* line 179, ../assets/sass/landing-page.scss */
p.copyright {
  margin: 15px 0 0;
}

/*# sourceMappingURL=landing-page.css.map */
